import React from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import ArrowLink from 'src/components/Link/ArrowLink';
import AspectWrapper from 'src/components/AspectWrapper';
import Image from 'src/components/Image';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Tag from 'src/components/typography/Tag';
import theme from 'src/styles/theme';

const SmallContentWrapper = styled('div')`
    display: flex;
    height: auto;

    &.left {
        flex-direction: row-reverse;
    }

    ${below.tablet_s} {
        display: block;
    }
`;

const SmallContentImageWrapper = styled('div')`
    height: 100%;
    width: 100%;
    overflow: hidden;

    ${above.tablet_s} {
        width: calc(4 * 100%/12);
    }
`;

const SmallContentTextWrapper = styled('div')`
    position: relative;
    width: 100%;

    ${below.tablet_s} {
        &:after {
            content: "";
            display: block;
            width: 100%;
            padding-top: 100%;
        }
    }

    ${above.tablet_s} {
        width: calc(8 * 100%/12);
    }

    ${theme.spacing.tablet._32('padding')};
`;

const SmallContentText = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;

    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.mobile._12('padding-right')};
    ${theme.spacing.mobile._24('padding-top')};
    ${theme.spacing.mobile._20('padding-bottom')};

    ${theme.spacing.tablet._20('padding')};
    ${theme.spacing.tablet._24('padding-bottom')};

    ${theme.spacing.desktop._32('padding')};

    ${above.desktop_s} {
        width: calc(6 * 100%/8);
    }

    ${above.desktop_l} {
        width: calc(6 * 100%/8);
    }
`;

const ContentTag = styled(Tag)`
    ${theme.spacing.mobile._16('margin-bottom')};
    ${theme.spacing.tablet._32('margin-bottom')};
    ${theme.spacing.desktop._40('margin-bottom')};
`;

const SmallContentHeading = styled('div')`
    line-height: 1.15em;

    ${theme.fontSize.sans.mobile._24};
    ${theme.fontSize.sans.tablet._24};
    ${theme.fontSize.sans.desktop._40};
`;

const BigContentWrapper = styled('div')`
    overflow: hidden;

    &.right {
        padding-left: calc(4 * 100%/12);
    }

    ${below.sm} {
        padding-left: 0 !important;
    }
`;

const ContentImage = styled(Image)`
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: 1;
    transform: scale(1.1);

    &.standard {
        filter: blur(${theme.blur.standard.desktop});
    }
    ${below.tablet_s} {
        &.standard {
            filter: blur(${theme.blur.standard.mobile});
        }
    }
`;

const BigContentTextWrapper = styled('div')`
    height: 100%;
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.mobile._12('padding-right')};
    ${theme.spacing.mobile._16('padding-top')};
    ${theme.spacing.mobile._16('padding-bottom')};

    ${theme.spacing.tablet._20('padding')};
    ${theme.spacing.tablet._24('padding-bottom')};

    ${theme.spacing.desktop._32('padding')};

    &.right {
        width: calc(8 * 100%/12);
    }
    .arrowlink {
        color: red;
    }

    ${below.tablet_s} {
        width: 100%;
    }
`;

const BigContentTag = styled(Tag)`
    ${theme.spacing.mobile._32('margin-bottom')};
    ${theme.spacing.tablet._32('margin-bottom')};
    ${theme.spacing.desktop._64('margin-bottom')};
`;

const BigContentHeading = styled('div')`
    font-size: 34.53px;
    font-family: sans-serif;
    line-height: 1.15;

    ${theme.fontSize.sans.mobile._40};
    ${theme.fontSize.sans.tablet._40};
    ${theme.fontSize.sans.desktop._80};
`;

const ContentModule = ({data}) => {
    const {
        backgroundColor,
        backgroundImage,
        blurAmount,
        heading,
        image,
        link,
        mode,
        tag,
        textAlignment,
        textColor
    } = data;

    const moduleBackground = theme.color[backgroundColor] || theme.color.white;
    const moduleTextColor = theme.backgroundTextColor[backgroundColor] || theme.color.black;
    const direction = textAlignment === 'right' ? 'right' : 'left';
    const bigModuleTextColor = theme.color[textColor] || theme.color.white;
    const imageSize = mode === 'small' ? 'medium' : blurAmount !== 'none' ? 'small' : 'large';
    const backgroundImages =
        backgroundImage.mobile.image[`${imageSize}`] &&
        backgroundImage.desktop.image[`${imageSize}`]
            ? [
                backgroundImage.mobile.image[`${imageSize}`][0],
                {
                    ...backgroundImage.desktop.image[`${imageSize}`][0],
                    media: `${above.tablet_s.replace('@media ', '')}`,
                },
            ]
            : backgroundImage.desktop.image[`${imageSize}`]
            ? backgroundImage.desktop.image[`${imageSize}`]
            : backgroundImage.mobile.image[`${imageSize}`];

        const images =
            image.mobile.image[`${imageSize}`] &&
            image.desktop.image[`${imageSize}`]
                ? [
                    image.mobile.image[`${imageSize}`][0],
                    {
                        ...image.desktop.image[`${imageSize}`][0],
                        media: `${above.tablet_s.replace('@media ', '')}`,
                    },
                ]
                : image.desktop.image[`${imageSize}`]
                ? image.desktop.image[`${imageSize}`]
                : image.mobile.image[`${imageSize}`];

    return (
        <>
            {mode === 'small' &&
                <SmallContentWrapper style={{backgroundColor: moduleBackground}} className={direction}>
                    <SmallContentImageWrapper>
                        <AspectWrapper ratio={[1 / 1, null, 2 / 3]}>
                            <ContentImage fluid={images}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                }}
                            />
                        </AspectWrapper>
                    </SmallContentImageWrapper>

                    <SmallContentTextWrapper style={{color: moduleTextColor}}>
                        <SmallContentText>
                            <div>
                                <ContentTag>{tag}</ContentTag>
                                <SmallContentHeading>
                                    {heading}
                                </SmallContentHeading>
                            </div>
                            {link && !!link.url && !!link.text &&
                                <div>
                                    <ArrowLink color={moduleTextColor} src={link.url}>{link.text}</ArrowLink>
                                </div>
                            }
                        </SmallContentText>
                    </SmallContentTextWrapper>
                </SmallContentWrapper>
            }

            {mode === 'large' &&
                <AspectWrapper ratio={[1 / 1, null, 2 / 1]}>
                    <BigContentWrapper className={direction} style={{color: bigModuleTextColor}}>
                        <ContentImage className={blurAmount} fluid={backgroundImages}
                            style={{
                                height: '100%',
                                width: '100%',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                            }}
                        />
                        <BigContentTextWrapper>
                            <div>
                                <BigContentTag>{tag}</BigContentTag>
                                <BigContentHeading>
                                    {heading}
                                </BigContentHeading>
                            </div>
                            {link && !!link.url && !!link.text &&
                                <div>
                                    <ArrowLink isContentModule color={bigModuleTextColor} src={link.url}>{link.text}</ArrowLink>
                                </div>
                            }
                        </BigContentTextWrapper>
                    </BigContentWrapper>
                </AspectWrapper>
            }
        </>
    );
};

ContentModule.propTypes = {
    data: PropTypes.shape({
        backgroundColor: PropTypes.string.isRequired,
        backgroundImage: PropTypes.object,
        blurAmount: PropTypes.string.isRequired,
        heading: PropTypes.string.isRequired,
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        link: PropTypes.object,
        mode: PropTypes.string.isRequired,
        tag: PropTypes.string,
        textAlignment: PropTypes.string.isRequired,
        textColor: PropTypes.string.isRequired,
    }),
};

ContentModule.defaultProps = {
    data: {
        backgroundImage: {},
        image: {},
        link: {},
        tag: '',
    },
};

export default ContentModule;
